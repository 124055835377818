import React from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Layout from '../components/layout'
import BlogPosts from '../components/BlogPosts'
import { RichText } from 'prismic-reactjs'

// Query for the Blog Home content in Prismic
export const query = graphql`
  {
    prismic {
      allBlog_homepages(uid: null) {
        edges {
          node {
            blog_title
            blog_richtext
            _linkType
            _meta {
              id
              type
            }
          }
        }
      }
      allBlog_postss(sortBy: blog_date_DESC) {
        edges {
          node {
            blog_title
            blog_date
            blog_header_image
            blog_description
            blog_section
            _linkType
            _meta {
              id
              type
              uid
            }
            body {
              ... on PRISMIC_Blog_postsBodyCustom {
                type
                label
                primary {
                  custom_rich_text
                  custom_highlight
                  custom_image
                  custom_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Using the queried Blog Home document data, we render the top section
const BlogHomeHead = ({ home }) => {
  return (
    <div className="home-header container">
      <h1 className="blog-title mb-4">{RichText.asText(home.blog_title)}</h1>
      {/* <p className="blog-description">{RichText.asText(home.blog_richtext)}</p> */}
      <h3 className="font-semibold text-xl md:text-base text-gray-800 rounded">Catégories disponibles :</h3>
      <AniLink
        fade
        className="nav-links nav-links-blog font-muli text-2xl md:text-xl text-gray-800 rounded"
        to="/blog/astuces"
      >
        Astuces
      </AniLink>
      <AniLink
        fade
        className="nav-links nav-links-blog font-muli text-2xl md:text-xl text-gray-800 rounded"
        to="/blog/sommeil-nouveaux-nes"
      >
        Sommeil des nouveau-nés
      </AniLink>
      <AniLink
        fade
        className="nav-links nav-links-blog font-muli text-2xl md:text-xl text-gray-800 rounded"
        to="/blog/a-savoir-sur-le-sommeil"
      >
        À savoir sur le sommeil
      </AniLink>
      <AniLink
        fade
        className="nav-links nav-links-blog font-muli text-2xl md:text-xl text-gray-800 rounded"
        to="/blog/organisation-du-sommeil"
      >
        Organisation du sommeil
      </AniLink>
    </div>
  )
}

const blog = ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const home = data.prismic.allBlog_homepages.edges[0].node
  const posts = data.prismic.allBlog_postss.edges

  // if (!doc) return null

  return (
    <Layout>
      <BlogHomeHead home={home} />
      <BlogPosts posts={posts} />
    </Layout>
  )
}
export default blog
